.page-container {
  width: 97%;
}

@media (min-width: 576px) {
  .page-container {
    height: 100% !important;
  }
  .page-row {
    height: 100% !important;
  }
}

.page-content-container {
}

.page-title {
}
