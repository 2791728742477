.page-footer {
    background-color: black;
    color: white;
    width: 100%;

    a {
        color: grey;
    }
}

.footer-icon {
    width: 30px;
}
