.sidebar {
    color: white;
    background-color: black;
    padding: 20px;
}
@media (min-width: 576px) {
    .sidebar {
        height: 100% !important;
    }
}

.sidebar a {
    height: 100%;
    font-size: 20px;
    color: white;
    text-decoration: none;
}
@media (min-width: 576px) {
    .sidebar a {
        font-size: 15px;
    }
}
@media (min-width: 768px) {
    .sidebar a {
        font-size: 25px;
    }
}

.sidebar.title {
    font-size: 20px;
    text-transform: uppercase;
}

.sidebar.colLogo {
    padding-right: 0px;
}

.sidebar.navbar-toggler {
    color: white;
}

.sidebar.menu-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-right: -15px;
}

.logo {
    max-width: 150px;
    height: auto;
    margin-bottom: 20px;
}
@media (min-width: 768px) {
    .logo {
        max-width: 250px;
    }
}
