.title {
    font-weight: bold;
    font-size: medium;
}

.date {
    font-style: italic;
}

.text {
    
}